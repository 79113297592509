var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { attrs: { flat: "" } }, [
    _c("h1", [_vm._v("Aktuelle rechtliche Informationen")]),
    _c("h2", { staticClass: "pt-4" }, [_vm._v("Ihr Widerrufsrecht")]),
    _vm._v(
      " Hier finden Sie die Widerrufsbelehrung sowie das Widerrufsformular der NEW Energie. Was möchten Sie tun? "
    ),
    _c("ul", [
      _c("li", [
        _vm._v("Das "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.new-energie.de/widerruf/online-formular",
              target: "_blank",
            },
          },
          [_vm._v("Online-Widerrufsformular")]
        ),
        _vm._v(" nutzen"),
      ]),
      _c("li", [
        _vm._v("Das "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.new-energie.de/mediathek/widerrufsformular_der_new_energie.pdf",
            },
          },
          [_vm._v("Widerrufsformular")]
        ),
        _vm._v(" ausdrucken"),
      ]),
    ]),
    _c("h2", { staticClass: "pt-4" }, [
      _vm._v("Datenschutz bei der NEW Energie"),
    ]),
    _vm._v(" Ihre Daten sind uns wichtig - informieren Sie sich! "),
    _c("ul", [
      _c("li", [
        _vm._v("Direkt zu den "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.new-energie.de/datenschutzhinweise",
              target: "_blank",
            },
          },
          [_vm._v("Datenschutzhinweisen")]
        ),
      ]),
    ]),
    _c("h2", { staticClass: "pt-4" }, [
      _vm._v(
        "Hinweis für Beanstandungen von Verbrauchern im Sinne des § 13 BGB"
      ),
    ]),
    _c("p", [
      _vm._v(
        "Fragen oder Beschwerden im Zusammenhang mit Ihrer Energielieferung können an unseren Kundenservice per Post (NEW Niederrhein Energie und Wasser GmbH, 41220 Mönchengladbach) gerichtet werden oder nutzen Sie alternativ unser "
      ),
      _c(
        "a",
        {
          attrs: { href: "https://www.new-energie.de/kontakt/anliegenauswahl" },
        },
        [_vm._v("Kontaktformular")]
      ),
      _vm._v("."),
    ]),
    _c("p", [
      _vm._v(
        "Der Verbraucherservice der Bundesnetzagentur stellt Ihnen Informationen über das geltende Recht, Ihre Rechte als Haushaltskunde und über Streitbeilegungsverfahren für die Bereiche Elektrizität und Gas zur Verfügung und ist unter folgenden Kontaktdaten erreichbar:"
      ),
    ]),
    _c("p", [
      _c("strong", [_vm._v("Bundesnetzagentur")]),
      _c("br"),
      _vm._v(" Postfach 8001"),
      _c("br"),
      _vm._v(" 53105 Bonn"),
      _c("br"),
      _vm._v(" Tel.: "),
      _c("a", { attrs: { target: "_self", href: "tel:030 22480-500" } }, [
        _vm._v("030 22480-500"),
      ]),
      _c("br"),
      _vm._v(" Fax: 030 22480-323"),
      _c("br"),
      _vm._v(" E-Mail: "),
      _c(
        "a",
        {
          attrs: {
            target: "_self",
            href: "mailto:verbraucherservice-energie@bnetza.de",
          },
        },
        [_vm._v("verbraucherservice-energie(at)bnetza.de")]
      ),
      _c("br"),
      _vm._v(" Web: "),
      _c(
        "a",
        {
          attrs: { target: "blank", href: "https://www.bundesnetzagentur.de" },
        },
        [_vm._v("www.bundesnetzagentur.de")]
      ),
    ]),
    _c("p", [
      _vm._v(
        " Zur Beilegung von Streitigkeiten kann ein Schlichtungsverfahren bei der „Schlichtungsstelle Energie e. V.“ beantragt werden. Voraussetzung dafür ist, dass der Kundenservice unseres Unternehmens angerufen wurde und keine beidseitig zufriedenstellende Lösung gefunden wurde. "
      ),
    ]),
    _c("p", [
      _c("strong", [_vm._v("Schlichtungsstelle Energie e. V.")]),
      _c("br"),
      _vm._v(" Friedrichstraße 133"),
      _c("br"),
      _vm._v(" 10117 Berlin"),
      _c("br"),
      _vm._v(" Tel.: "),
      _c("a", { attrs: { target: "_self", href: "tel:030 2757240-0" } }, [
        _vm._v("030 2757240-0"),
      ]),
      _c("br"),
      _vm._v(" Fax: 030 2757240-69"),
      _c("br"),
      _vm._v(" E-Mail: "),
      _c(
        "a",
        {
          attrs: {
            target: "_self",
            href: "mailto:info@schlichtungsstelle-energie.de",
          },
        },
        [_vm._v("info(at)schlichtungsstelle-energie.de")]
      ),
      _c("br"),
      _vm._v(" Web: "),
      _c(
        "a",
        {
          attrs: {
            target: "blank",
            href: "https://www.schlichtungsstelle-energie.de",
          },
        },
        [_vm._v("www.schlichtungsstelle-energie.de")]
      ),
      _c("br"),
    ]),
    _c("p", [
      _c("strong", [
        _vm._v("Information zum Verbraucherstreitbeilegungsgesetz"),
      ]),
      _c("br"),
      _vm._v(
        " Unser Unternehmen nimmt an keinem freiwilligen Verbraucherstreitbeilegungsverfahren teil. "
      ),
    ]),
    _c("h2", { staticClass: "pt-4" }, [
      _vm._v("Plattform zur Klärung von Online-Streitigkeiten"),
    ]),
    _c("p", [
      _vm._v(
        "Bei der OS-Plattform der EU handelt es sich um eine Anlaufstelle für Verbraucher und Verbraucherinnen und Unternehmen, die Streitigkeiten aus Online-Verträgen außergerichtlich und freiwillig beilegen wollen. Informieren Sie sich direkt auf der "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage",
          },
        },
        [_vm._v(" OS-Plattform.")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }