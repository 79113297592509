<template>
    <v-card flat>
        <h1>Aktuelle rechtliche Informationen</h1>

        <h2 class="pt-4">Ihr Widerrufsrecht</h2>

        Hier finden Sie die Widerrufsbelehrung sowie das Widerrufsformular der NEW Energie. Was möchten Sie tun?
        <ul>
            <li>Das <a href="https://www.new-energie.de/widerruf/online-formular"
                    target="_blank">Online-Widerrufsformular</a> nutzen</li>
            <li>Das <a
                    href="https://www.new-energie.de/mediathek/widerrufsformular_der_new_energie.pdf">Widerrufsformular</a>
                ausdrucken</li>
        </ul>

        <h2 class="pt-4">Datenschutz bei der NEW Energie</h2>

        Ihre Daten sind uns wichtig - informieren Sie sich!
        <ul>
            <li>Direkt zu den <a href="https://www.new-energie.de/datenschutzhinweise"
                    target="_blank">Datenschutzhinweisen</a></li>
        </ul>
        <h2 class="pt-4">Hinweis für Beanstandungen von Verbrauchern im Sinne des § 13 BGB</h2>

        <p>Fragen oder Beschwerden im Zusammenhang mit Ihrer Energielieferung können an unseren Kundenservice per
            Post (NEW Niederrhein Energie und Wasser GmbH, 41220 Mönchengladbach) gerichtet werden oder nutzen Sie
            alternativ unser <a href="https://www.new-energie.de/kontakt/anliegenauswahl">Kontaktformular</a>.</p>
        <p>Der Verbraucherservice der Bundesnetzagentur stellt Ihnen Informationen über das geltende Recht, Ihre
            Rechte als Haushaltskunde und über Streitbeilegungsverfahren für die Bereiche Elektrizität und Gas zur
            Verfügung und ist unter folgenden Kontaktdaten erreichbar:</p>
        <p><strong>Bundesnetzagentur</strong><br>
            Postfach 8001<br>
            53105 Bonn<br>
            Tel.: <a target="_self" href="tel:030 22480-500">030 22480-500</a><br>
            Fax: 030 22480-323<br>
            E-Mail: <a target="_self" href="mailto:verbraucherservice-energie@bnetza.de">verbraucherservice-energie(at)bnetza.de</a><br>
            Web: <a target="blank" href="https://www.bundesnetzagentur.de">www.bundesnetzagentur.de</a></p>
        <p>
            Zur Beilegung von Streitigkeiten kann ein Schlichtungsverfahren bei der „Schlichtungsstelle Energie e.
            V.“ beantragt werden. Voraussetzung dafür ist, dass der Kundenservice unseres Unternehmens angerufen
            wurde und keine beidseitig zufriedenstellende Lösung gefunden wurde.
        </p>
        <p><strong>Schlichtungsstelle Energie e. V.</strong><br>
            Friedrichstraße 133<br>
            10117 Berlin<br>
            Tel.: <a target="_self" href="tel:030 2757240-0">030 2757240-0</a><br>
            Fax: 030 2757240-69<br>
            E-Mail: <a target="_self" href="mailto:info@schlichtungsstelle-energie.de">info(at)schlichtungsstelle-energie.de</a><br>
            Web: <a target="blank" href="https://www.schlichtungsstelle-energie.de">www.schlichtungsstelle-energie.de</a><br>
        </p>
        <p><strong>Information zum Verbraucherstreitbeilegungsgesetz</strong><br>
            Unser Unternehmen nimmt an keinem freiwilligen Verbraucherstreitbeilegungsverfahren teil.
        </p>

        <h2 class="pt-4">Plattform zur Klärung von Online-Streitigkeiten</h2>

        <p>Bei der OS-Plattform der EU handelt es sich um eine Anlaufstelle für Verbraucher und Verbraucherinnen und
            Unternehmen, die Streitigkeiten aus Online-Verträgen außergerichtlich und freiwillig beilegen wollen.
            Informieren Sie sich direkt auf der <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
                OS-Plattform.</a>
        </p>
    </v-card>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {},
    computed: {},
    mounted() {
    }
}
</script>

<style></style>